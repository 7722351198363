import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const LazyHomePage = lazy(() => import("./pages/HomePage"));
const LazyLogin = lazy(() => import("./pages/auth/Login"));
const LazySignup = lazy(() => import("./pages/auth/Signup"));
const LazyShopingCart = lazy(() => import("./pages/ShoppingCart"));
const LazyCheckout = lazy(() => import("./pages/Checkout"));
const LazyBrandHeroes = lazy(() => import("./pages/BrandHeroes"));
const LazyBlog = lazy(() => import("./pages/Blog"));
const LazyBlogDetails = lazy(() => import("./pages/BlogDetails"));
const LazyAboutus = lazy(() => import("./pages/Aboutus"));
const LazyCareer = lazy(() => import("./pages/career"));
const LazyCareerDetails = lazy(() => import("./pages/careerDetails"));
const LazySitemap = lazy(() => import("./pages/sitemap"));
const LazyCameras = lazy(() => import("./pages/Cameras"));
const LazyCamerasDetails = lazy(() => import("./pages/CamerasDetails"));
const LazyContactUs = lazy(() => import("./pages/Contactus"));
const LazyPrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const LazyFaq = lazy(() => import("./pages/Faq"));
const LazyWorldOfFitSpark = lazy(() => import("./pages/WorldOfFitSpark"));
const LazySnowBoarding = lazy(() => import("./pages/SnowBoarding"));
const LazyAccessories = lazy(() => import("./pages/Accessories"));
const LazyDownload = lazy(() => import("./pages/Download"));
const LazyMyAccount = lazy(() => import("./pages/MyAccount"));
const LazyOrder = lazy(() => import("./pages/OrderList"));
const LazyChangePassword = lazy(() => import("./pages/ChangePassword"));
const LazyMyAddress = lazy(() => import("./pages/MyAddress"));



function App() {
  return (
    // <HomePage />
    <BrowserRouter>
      <Routes>
        {/* <Route path="*" element={<NoPage />} /> */}
        <Route
          path="/login"
          element={
            <Suspense fallback={<div></div>}>
              <LazyLogin />
            </Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <Suspense fallback={<div></div>}>
              <LazySignup />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<div></div>}>
              <LazyHomePage />
            </Suspense>
          }
        />
        <Route
          path="/shoping_cart"
          element={
            <Suspense fallback={<div></div>}>
              <LazyShopingCart />
            </Suspense>
          }
        />
        <Route
          path="/checkout"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCheckout />
            </Suspense>
          }
        />
        <Route
          path="/brand_heroes"
          element={
            <Suspense fallback={<div></div>}>
              <LazyBrandHeroes />
            </Suspense>
          }
        />
        <Route
          path="/blog"
          element={
            <Suspense fallback={<div></div>}>
              <LazyBlog />
            </Suspense>
          }
        />
        <Route
          path="/blog_detail"
          element={
            <Suspense fallback={<div></div>}>
              <LazyBlogDetails />
            </Suspense>
          }
        />
        <Route
          path="/about_us"
          element={
            <Suspense fallback={<div></div>}>
              <LazyAboutus />
            </Suspense>
          }
        />
         <Route
          path="/career"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCareer />
            </Suspense>
          }
        />
        <Route
          path="/career_details"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCareerDetails />
            </Suspense>
          }
        />
         <Route
          path="/sitemap"
          element={
            <Suspense fallback={<div></div>}>
              <LazySitemap />
            </Suspense>
          }
        />
        <Route
          path="/cameras"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCameras />
            </Suspense>
          }
        />
        <Route
          path="/cameras_details"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCamerasDetails />
            </Suspense>
          }
        />
        <Route
          path="/contact_us"
          element={
            <Suspense fallback={<div></div>}>
              <LazyContactUs />
            </Suspense>
          }
        />
        <Route
          path="/privacy_policy"
          element={
            <Suspense fallback={<div></div>}>
              <LazyPrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/faq"
          element={
            <Suspense fallback={<div></div>}>
              <LazyFaq />
            </Suspense>
          }
        />
        <Route
          path="/world_of_fitspark"
          element={
            <Suspense fallback={<div></div>}>
              <LazyWorldOfFitSpark />
            </Suspense>
          }
        />
        <Route
          path="/snow_boarding"
          element={
            <Suspense fallback={<div></div>}>
              <LazySnowBoarding />
            </Suspense>
          }
        />
        <Route
          path="/accessories"
          element={
            <Suspense fallback={<div></div>}>
              <LazyAccessories />
            </Suspense>
          }
        />
        <Route
          path="/download"
          element={
            <Suspense fallback={<div></div>}>
              <LazyDownload />
            </Suspense>
          }
        />
        <Route
          path="/my_account"
          element={
            <Suspense fallback={<div></div>}>
              <LazyMyAccount />
            </Suspense>
          }
        />
        <Route
          path="/order"
          element={
            <Suspense fallback={<div></div>}>
              <LazyOrder />
            </Suspense>
          }
        />
        <Route
          path="/change_password"
          element={
            <Suspense fallback={<div></div>}>
              <LazyChangePassword />
            </Suspense>
          }
        />
        <Route
          path="/my_address"
          element={
            <Suspense fallback={<div></div>}>
              <LazyMyAddress />
            </Suspense>
          }
        />
















      </Routes>
    </BrowserRouter>
  );
}

export default App;
